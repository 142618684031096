import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import {
    ServerError,
    fetchOrganizationCompanyDetailsThunk,
    getQueryValueFromCurrentUrl,
    getText,
    prepareFormData,
    requestThunk,
} from "swiipe.portal.shared"
import { TSetShowSpinnerFunc } from "../../components/infobox/InfoBoxEditor"
import { addModalThunk } from "../../store/thunks/modalThunks"
import { TSwiipePlan } from "../../type/IProvider"
import { SwMerchantContactDto } from "../../type/SwMerchantMainContactDto"
import { TOrganizationType } from "../../type/TOrganizationType"
import { IFeatureDetails } from "../../type/feature/IFeatureDetails"
import { StoreState } from "../StoreState"
import { merchantReducerActions } from "../reducers/merchantReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { endpoints } from "./../../data/endpoints"
import { AddWebshopFormModel } from "./../../type/AddWebshopFormModel"
import { IApiKey, TApiKeyScope } from "./../../type/IApiKey"
import { MerchantCompanyDetailsFormModel } from "./../../type/MerchantCompanyDetailsFormModel"
import { MerchantSignupFormModel } from "./../../type/merchantSignupFormModel"
import { MixedMerchantDetails } from "./../../type/mixedmerchantdetails"
import { merchantSelectors } from "./../reducers/merchantReducer"
import { fetchInvoicingPricesThunk } from "./billingThunks"
import { getSelectedCompanyDetailsFromSearch } from "./organizationThunks"
import { ensureUserRelationsThunk } from "./userRelationThunks"

export const fetchMerchantDataThunk =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (!force && merchantSelectors.merchantDetails(getState())) {
            // Merchant data already fetched
            await dispatch(tryToSwitchWebshopFromQueryParam(merchantSelectors.merchantDetails(getState())))
            return
        }

        const merchantDetails = await dispatch(
            requestThunk<MixedMerchantDetails>(endpoints.Identity.getMerchantDetails, {
                params: { merchantId: currentUserRelation.id },
            })
        )

        dispatch(
            fetchOrganizationCompanyDetailsThunk(
                currentUserRelation.id,
                merchantDetails.swMerchant.countryCode,
                merchantDetails.swMerchant.registrationNumber,
                TOrganizationType.SwMerchant
            )
        )

        dispatch(fetchInvoicingPricesThunk(false))

        merchantDetails.webshops = merchantDetails.webshops.sort(function (a, b) {
            const hostA = a.hostName,
                hostB = b.hostName
            if (hostA < hostB) return -1
            if (hostA > hostB) return 1
            return 0
        })

        const switchedWebshop = await dispatch(tryToSwitchWebshopFromQueryParam(merchantDetails))

        dispatch(merchantReducerActions.setMerchantDetails(currentUserRelation.id, merchantDetails))
        if (!switchedWebshop) {
            dispatch(setLastSelectedWebshopIdThunk())
        }

        await dispatch(fetchSwiipePlanThunk(currentUserRelation.id))
    }

let webshopSwitchPerformed = false
const tryToSwitchWebshopFromQueryParam =
    (merchantDetails?: MixedMerchantDetails): ThunkAction<Promise<boolean>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        if (!merchantDetails || webshopSwitchPerformed) {
            return false
        }
        const webshopIdFromQuery = getQueryValueFromCurrentUrl("webshopId") ?? ""
        if (!webshopIdFromQuery) {
            return false
        }
        const webshop = merchantDetails.webshops.find((w) => w.webshopId === webshopIdFromQuery)
        if (!webshop) {
            return false
        }
        webshopSwitchPerformed = true
        dispatch(merchantReducerActions.setSelectedWebshop(merchantDetails.swMerchant.swMerchantId, webshopIdFromQuery))
        return true
    }

export const getMerchantDataThunk =
    (swMerchantId: string): ThunkAction<Promise<MixedMerchantDetails>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const merchantDetails = await dispatch(
            requestThunk<MixedMerchantDetails>(endpoints.Identity.getMerchantDetails, {
                params: { merchantId: swMerchantId },
            })
        )

        return merchantDetails
    }

export const setLastSelectedWebshopIdThunk =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        const merchantDetails = merchantSelectors.merchantDetails(getState())
        if (!merchantDetails || !merchantDetails.swMerchant || merchantDetails.webshops.length === 0) {
            return
        }
        let lastSelectedWebshopId = localStorage[merchantDetails.swMerchant.swMerchantId + "_selectedWebshopId"]

        if (!lastSelectedWebshopId || !merchantDetails.webshops.find((w) => w.webshopId === lastSelectedWebshopId)) {
            lastSelectedWebshopId = merchantDetails.webshops[0].webshopId
        }

        dispatch(merchantReducerActions.setSelectedWebshop(merchantDetails.swMerchant.swMerchantId, lastSelectedWebshopId))
    }

export const fetchSwiipePlanThunk =
    (swMerchantId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const swiipePlan = await dispatch(
            requestThunk<{ swiipePlan?: TSwiipePlan }>(endpoints.Identity.getSwiipePlan(swMerchantId))
        )
        if (swiipePlan.swiipePlan !== undefined) {
            dispatch(merchantReducerActions.setSwiipePlan(swMerchantId, swiipePlan.swiipePlan))
        }
    }

export const updateCompanyDetailsMerchant =
    (merchantId: string, form: MerchantCompanyDetailsFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.setCompanyDetails, {
                data: {
                    organizationId: merchantId,
                    organizationType: TOrganizationType.SwMerchant,
                    ...prepareFormData(form, []),
                },
            })
        )

        if (form.cvr) {
            await dispatch(updateMerchantCvrThunk(merchantId, form.cvr))
        }

        await dispatch(fetchMerchantDataThunk(true))
        await dispatch(ensureUserRelationsThunk(true))
    }

export const updateMerchantCvrThunk =
    (merchantId: string, cvr: string, withUpdate = false): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.updateMerchantCvr, {
                data: {
                    swMerchantId: merchantId,
                    cvr: cvr,
                },
            })
        )

        if (withUpdate) {
            await dispatch(fetchMerchantDataThunk(true))
        }
    }

export const addWebshopThunk =
    (form: AddWebshopFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())!
        await dispatch(
            requestThunk<void>(endpoints.Identity.createWebshop, {
                data: { ...prepareFormData(form, []), merchantId: currentUserRelation.id },
            })
        )
        await dispatch(fetchMerchantDataThunk(true))
        await dispatch(ensureMerchantApiKeys(true))
    }

export const deleteWebshopThunk =
    (
        id: string,
        clientId: string,
        setShowSpinner: TSetShowSpinnerFunc,

        swMerchantId: string,
        fallbackWebshopId: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const modalAnswer = await dispatch(
            addModalThunk({
                type: "confirmDangerousAction",
                title: getText("personal.deletewebsite"),
                promptText: getText("personal.deletewebsite2"),
                customSubmitText: getText("common.yesdelete"),
                customCancelText: getText("common.nogoback"),
            })
        )
        if (modalAnswer.type !== "accepted") {
            return
        }
        setShowSpinner(true)

        await dispatch(
            requestThunk<void>(endpoints.Core.deleteWebshop, {
                data: {
                    webshopId: id,
                    clientId: clientId,
                },
            })
        )

        await dispatch(merchantReducerActions.setSelectedWebshop(swMerchantId, fallbackWebshopId))
        await dispatch(fetchMerchantDataThunk(true))

        setShowSpinner(false)
    }

export const updateSwMerchantContactDataThunk =
    (form: SwMerchantContactDto, swMerchantId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.setMerchantDetails, {
                data: {
                    swMerchantId: swMerchantId,
                    swMerchantContacts: [
                        {
                            ...prepareFormData(form, []),
                        },
                    ],
                },
            })
        )
        await dispatch(fetchMerchantDataThunk(true))
    }

export const getOwnersContactInfoThunk =
    (swMerchantId: string): ThunkAction<Promise<SwMerchantContactDto>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const result = await dispatch(
            requestThunk<{ ownerContact: SwMerchantContactDto }>(endpoints.Identity.getOwnersContactInfo, {
                params: {
                    merchantId: swMerchantId,
                },
            })
        )

        return result.ownerContact
    }

export const deleteMerchantContactThunk =
    (swMerchantId: string, contactId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.deleteMerchantContact, {
                data: {
                    merchantId: swMerchantId,
                    contactId,
                },
            })
        )

        await dispatch(fetchMerchantDataThunk(true))
    }

interface IWebshopSetData {
    webshopId: string
    mcc: string
}

export const adminUpdateMerchantWebshopDataThunk =
    (swMerchantId: string, webshops: IWebshopSetData[]): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.setMerchantDetails, {
                data: {
                    swMerchantId,
                    webshops,
                },
            })
        )
        await dispatch(fetchMerchantDataThunk(true))
    }

export const merchantSignupThunk =
    (form: MerchantSignupFormModel): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const preparedForm = prepareFormData(form, [])

        const companyData = await dispatch(getSelectedCompanyDetailsFromSearch(preparedForm.companyNumber))

        await dispatch(
            requestThunk<void>(
                endpoints.Identity.createMerchant,
                {
                    data: {
                        userDetails: {
                            email: preparedForm.email,
                            firstName: preparedForm.firstName,
                            lastName: preparedForm.lastName,
                            phone: preparedForm.phone,
                            country: preparedForm.country,
                            language: preparedForm.language,
                        },
                        webshopBaseInfo: {
                            hostname: preparedForm.hostName,
                            platform: preparedForm.platform,
                        },
                        companyDetails: {
                            ...companyData,
                            companyName: preparedForm.companyName,
                        },
                        companyRegistrationNumber: preparedForm.companyNumber,
                    },
                },
                {
                    errorHandlers: [
                        {
                            errorType: "CompanyAlreadyRegisteredError",
                            handleError: async (error: ServerError) => {
                                const rawError = error.rawError?.toLowerCase()
                                await dispatch(
                                    addModalThunk({
                                        type: "modalCompanyAlreadyRegistered",
                                        subType: rawError?.includes("delete")
                                            ? rawError?.includes("owner")
                                                ? "ownerAndDeleted"
                                                : "deleted"
                                            : "default",
                                    })
                                )
                                return true
                            },
                        },
                    ],
                }
            )
        )
    }

export const ensureMerchantApiKeys =
    (force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(ensureUserRelationsThunk())

        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        if (!force && merchantSelectors.apiKeys(getState())) {
            // ApiKeys data already fetched
            return
        }

        const resp = await dispatch(requestThunk<{ apiKeys: IApiKey[] }>(endpoints.Identity.getApiKeys(currentUserRelation.id)))
        dispatch(merchantReducerActions.setMerchantApiKeys(currentUserRelation.id, resp.apiKeys))
    }

export const addMerchantApiKey =
    (scope: TApiKeyScope, integratorName: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const currentUserRelation = userRelationSelectors.currentUserRelation(getState())
        if (!currentUserRelation || currentUserRelation.relationType !== "Merchant") {
            return
        }

        await dispatch(
            requestThunk(endpoints.Identity.addApiKey(currentUserRelation.id), {
                data: {
                    scopeTypes: [scope],
                    integratorName,
                },
            })
        )
        await dispatch(ensureMerchantApiKeys(true))
    }

export const setSelectedWebshopIdThunk =
    (merchantId: string, webshopId: string): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch) => {
        dispatch(merchantReducerActions.setSelectedWebshop(merchantId, webshopId))
    }

export const fetchWebshopFeaturesDetailsThunk =
    (webshopId: string, force: boolean): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const features = merchantSelectors.featuresDetailsForWebshop(getState(), webshopId)
        if (!force && features) {
            return
        }

        const result = await dispatch(
            requestThunk<{ featuresDetails: IFeatureDetails[] }>(endpoints.Identity.getFeatureDetails, {
                params: {
                    webshopId: webshopId,
                },
            })
        )

        result.featuresDetails.forEach((d) => {
            dispatch(merchantReducerActions.setFeatureDetails(webshopId, d))
        })
    }

export const editWebshopAsMasterPartnerThunk =
    (
        swMerchantId: string,
        webshopId: string,
        hostName: string,
        mcc?: string,
        isDisabled?: boolean
    ): ThunkAction<void, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk<void>(endpoints.Identity.editWebshopAsMasterPartner, {
                data: {
                    swMerchantId: swMerchantId,
                    webshopData: {
                        webshopId: webshopId,
                        hostName: hostName,
                        mcc: mcc,
                        isDisabled,
                    },
                },
            })
        )

        await dispatch(fetchMerchantDataThunk(true))
    }
